<template>
  <div :class="`container-fluid alert-bar ${countdownSeconds ? 'alert-bar-with-countdown' : ''}`">
    <template v-if="content">
      <Icon :src="content.icon" path="icons/alert-bar" />
      <span :class="countdownSeconds ? 'alert-bar-content-wrapper' : ''">
        <span v-html="content.html"></span>
        <span v-if="countdownSeconds">
          {{ content.countdown.text }}
          <span class="alert-bar-countdown">
            {{countdown.day}}

            <template v-for="timePart in ['hours', 'minutes', 'seconds']">
              <template v-if="timePart !== 'hours'">&nbsp;:&nbsp;</template>
              <span :class="`countdown-digit ${animateCountdownDigitFlip && countdown[timePart][index] !== countdownPrevious[timePart][index] ? 'animate' : ''}`" :key="timePart+index" v-for="(digit, index) in countdown[timePart]">
                {{timePart !== 'seconds' || index !== 1 ? digit : '0' }}
                <span class="countdown-current-digit">{{digit}}</span>
                <span class="countdown-prev-digit">{{countdownPrevious[timePart][index]}}</span>
              </span>
            </template>
          </span>
        </span>
      </span>
    </template>
    <template v-else>
      <Icon src="truck" />
      <span>
        Doprava ZDARMA
        <template v-if="globalFreeShipping">
          na vše
        </template>
        <template v-else>
          od <template v-if="$api.cart">{{ $price($api.cart.freeShippingMin) }}</template>
        </template>
        <span class="additional-text">
          - doručujeme po celé ČR/SK
        </span>
      </span>
    </template>
    <button type="button" @click="hide()">✕</button>
  </div>
</template>

<script>
import Icon from "../Icon";
import LocalStorage from '@/local-storage';
export default {
  name: "AlertBar",
  components: {Icon},
  data() {
    return {
      countdownSeconds: null,
      countdownTimeout: null,
      countdownPrevious: null,
      animateCountdownDigitFlip: false,
    };
  },
  computed: {
    globalFreeShipping() {
      const freeShippingUntil = new Date(2024, 7 - 1, 14, 20);

      return (this.$api.cart && this.$api.cart.freeShippingMin <= 0) ||
          new Date() < freeShippingUntil;
    },
    content() {
      return this.$api._data.alertBar
    },
    localStorageKey() {
      if (!this.content) {
        return "alert-bar-free-shipping";
      }

      return "alert-bar-" + btoa(encodeURI(this.content.text)).substring(0, 32) + btoa(encodeURI(this.content.countdown.date + this.content.countdown.text)).substring(0, 32);
    },
    countdown() {
      const days = Math.floor(this.countdownSeconds / (60 * 60 * 24));
      const hours = Math.floor((this.countdownSeconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((this.countdownSeconds % (60 * 60)) / 60);
      const seconds = Math.floor(this.countdownSeconds % 60);

      const dayString = (() => {
        if (days <= 0) {
          return '';
        }

        if (days === 1) {
          return '1 den ';
        }

        if (days >= 2 && days <= 4) {
          return `${days} dny `;
        }

        return `${days} dní `;
      })();

      return {
        day: dayString,
        hours: hours.toString(),
        minutes: this.prependLeadingZero(minutes),
        seconds: this.prependLeadingZero(seconds),
      };
    }
  },
  methods: {
    hide() {
      LocalStorage.set(this.localStorageKey, {}, {
        days: 3
      });
      this.$emit('hide');
    },
    calculateCountdownSeconds() {
      if (!this.content || !this.content.countdown || !this.content.countdown.active) {
        this.$emit('hideCountdown');
        return;
      }

      this.animateCountdownDigitFlip = true;
      this.countdownSeconds = Math.max(new Date(this.content.countdown.date).getTime() - new Date().getTime(), 0) / 1000;

      setTimeout(() => {
        this.animateCountdownDigitFlip = false;
        this.countdownPrevious = {...this.countdown};
      }, 900);

      this.countdownTimeout = setTimeout(this.calculateCountdownSeconds, 1000);
    },
    prependLeadingZero(num) {
      return num.toString().padStart(2, "0");
    },
  },
  created() {
    this.$nextTick(() => {
      if (LocalStorage.get(this.localStorageKey)) {
        this.$emit('hide');
      }
    });

    this.countdownPrevious = {...this.countdown};
    this.calculateCountdownSeconds();
  },
  destroyed() {
    clearTimeout(this.countdownTimeout);
  }
}
</script>